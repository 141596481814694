/**
 * Created by Aleksandr on 05.06.2024
 */

var TravelMapWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options) {
        this.options = options || {};
        this.wideMode = cleverapps.resolution.mode;

        this.currentLocationId = this.options.moveNext ? cleverapps.meta.selectedLocationId() - 1 : cleverapps.meta.selectedLocationId();
        var content = this.createContent();

        this._super({
            content: content,
            title: "TravelMapWindow.title",
            name: "travelmapwindow",
            foreground: bundles.travel_map.frames.travel_foreground_png,
            styles: cleverapps.styles.TravelMapWindow
        });

        if (this.options.moveNext) {
            this.changeCurrent();
        }
    },

    setupChildren: function () {
        this._super();
        if (this.wideMode !== cleverapps.resolution.mode) {
            this.window.removeFromParent();
            this.onWindowLoaded(this.options);
            this.windowTitle.show();
        }
    },

    createContent: function () {
        var styles = cleverapps.styles.TravelMapWindow;

        var pages = [];
        var pagesCount = Math.ceil(cleverapps.meta.listAvailableLocations().length / TravelMapWindow.LEVELS_COUNT_IN_PAGE);

        this.items = [];
        for (var i = 0; i < pagesCount; i++) {
            pages.push(this.createPage(i));
        }

        var contentLayer = new cleverapps.Layout(pages, {
            direction: this.isHorizontal() ? cleverapps.UI.HORIZONTAL : cleverapps.UI.VERTICAL
        });

        var scrollView = this.scrollView = new cleverapps.UI.ScrollView(contentLayer, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE,
            direction: this.isHorizontal() ? cleverapps.UI.ScrollView.DIR_HORIZONTAL : cleverapps.UI.ScrollView.Vertical,
            scrollBarEnabled: true,
            mouseScroll: true
        });

        scrollView.setContentSize(pages[0].width, pages[0].height);
        scrollView.setAnchorPoint(0.5, 0.5);
        scrollView.setPositionRound(styles.scrollView.position);
        scrollView.scrollTo(this.items[this.currentLocationId]);

        return scrollView;
    },

    createPage: function (num) {
        var startLevel = num * TravelMapWindow.LEVELS_COUNT_IN_PAGE;
        var backgroundBundleName = "tm_background_" + num;
        if (!this.isHorizontal()) {
            backgroundBundleName += "_vertical";
        }
        var styles = cleverapps.styles.TravelMapWindow;
        var locationAmount = cleverapps.meta.listAvailableLocations().length;
        var bg = new cleverapps.Spine(bundles.travel_map.jsons[backgroundBundleName]);
        bg.setAnimation(0, "animation");

        for (var i = startLevel; i < locationAmount && i < startLevel + TravelMapWindow.LEVELS_COUNT_IN_PAGE; i++) {
            var state = TravelMapItemView.STATES.NEXT;

            if (i < this.currentLocationId) {
                state = TravelMapItemView.STATES.PASSED;
            }
            if (i === this.currentLocationId) {
                state = TravelMapItemView.STATES.CURRENT;
            }

            var item = new TravelMapItemView({
                locationId: i,
                state: state
            });

            item.setPositionRound(styles.item[i % TravelMapWindow.LEVELS_COUNT_IN_PAGE]);
            bg.addChild(item);

            this.items.push(item);
        }

        return bg;
    },

    isHorizontal: function () {
        return cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL;
    },

    changeCurrent: function () {
        var prev = this.items[cleverapps.meta.selectedLocationId() - 1];
        var current = this.items[cleverapps.meta.selectedLocationId()];
        var progress = cleverapps.meta.getSelectedLocation().getProgress();
        prev.progressBar.stopAllActions();
        prev.progressBar.updateProgress(progress.goal * 0.7, progress.goal);

        var actions = [
            new cc.DelayTime(0.3),
            new cc.CallFunc(function () {
                prev.progressBar.updateProgress(progress.goal, progress.goal, { animated: true });
            }),
            new cc.DelayTime(1.5),
            new cc.CallFunc(function () {
                prev.progressBar.receivePrize();
            }),
            new cc.DelayTime(1),
            new cc.CallFunc(function () {
                prev.updateState(TravelMapItemView.STATES.PASSED, true);
            }),
            new cc.DelayTime(1.5),
            new cc.CallFunc(function () {
                current.updateState(TravelMapItemView.STATES.CURRENT, true);
            }),
            new cc.DelayTime(5),
            new cc.CallFunc(function () {
                this.close();
            }.bind(this))
        ];

        this.runAction(new cc.Sequence(actions));
    },

    listBundles: function () {
        return ["travel_map"];
    }
});

TravelMapWindow.LEVELS_COUNT_IN_PAGE = 4;
TravelMapWindow.PAGES_COUNT = 1;

cleverapps.styles.TravelMapWindow = {
    padding: {
        x: 26,
        top: 25,
        bottom: 35
    },
    scrollView: {
        position: {
            x: { align: "center" },
            y: { align: "center" }
        }
    },

    item: [
        [
            {
                x: { align: "center", dx: -196 },
                y: { align: "center", dy: 499 }
            },
            {
                x: { align: "center", dx: -196 },
                y: { align: "center", dy: 499 }
            },
            {
                x: { align: "center", dx: -606 },
                y: { align: "center", dy: -76 }
            }
        ],
        [
            {
                x: { align: "center", dx: 200 },
                y: { align: "center", dy: 200 }
            },
            {
                x: { align: "center", dx: 200 },
                y: { align: "center", dy: 200 }
            },
            {
                x: { align: "center", dx: -199 },
                y: { align: "center", dy: 187 }
            }
        ],
        [
            {
                x: { align: "center", dx: -190 },
                y: { align: "center", dy: -100 }
            },
            {
                x: { align: "center", dx: -190 },
                y: { align: "center", dy: -100 }
            },
            {
                x: { align: "center", dx: 217 },
                y: { align: "center", dy: -73 }
            }

        ],
        [
            {
                x: { align: "center", dx: 190 },
                y: { align: "center", dy: -436 }
            },
            {
                x: { align: "center", dx: 190 },
                y: { align: "center", dy: -436 }
            },
            {
                x: { align: "center", dx: 596 },
                y: { align: "center", dy: 187 }
            }
        ]
    ],

    path: {
        x: { align: "left", dx: -75 },
        y: { align: "top", dy: -75 },
        offsetY: -90,
        dx: 50,
        dy: 25
    }
};