/**
 * Created by Aleksandr on 30.07.2024
 */

var TravelMapItemView = cc.Node.extend({
    ctor: function (options) {
        this._super();

        this.locationId = options.locationId;

        var styles = cleverapps.styles.TravelMapItemView;

        this.setContentSize(styles);
        var building = this.building = new cleverapps.Spine(bundles.travel_map.jsons["building_" + options.locationId + "_json"]);
        building.setPositionRound(styles.building);
        this.addChild(building);

        var titleBg = this.titleBg = new cc.Scale9Sprite(bundles.travel_map.frames.title_bg);
        titleBg.setPositionRound(styles.titleBg);
        titleBg.setContentSize(styles.titleBg.size);
        this.addChild(titleBg);

        var title = this.title = cleverapps.UI.generateTTFText("TravelMapWindow.item" + options.locationId, cleverapps.styles.FONTS.TRAVEL_MAP_ITEM_NAME_TEXT);

        var stateView = this.stateView = new cc.Node();
        stateView.setAnchorPoint(0.5, 0.5);
        stateView.setContentSize2(styles.stateView);

        if (cleverapps.meta.listActiveLocations().includes(options.locationId)
            && cleverapps.meta.selectedLocationId() !== options.locationId) {
            this.clickHandler = cleverapps.UI.onClick(this, function () {
                cleverapps.focusManager.distract({
                    focus: "TravelMap.gotoLocation",
                    action: function (f) {
                        cleverapps.meta.switchLocation(options.locationId);
                        cleverapps.scenes.replaceScene(new HomefixScene(), f);
                    }
                });
            });
        }

        cleverapps.UI.fitToBox(title, styles.titleSize);
        var titleLayout = this.titleLayout = new cleverapps.Layout([title, stateView], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.titleLayout.margin,
            padding: styles.padding
        });

        titleLayout.setPositionRound(styles.titleLayout.pos);
        titleBg.addChild(titleLayout);

        this.updateState(options.state);
    },

    updateState: function (state, animate) {
        this.titleBg.setSpriteFrame(state === TravelMapItemView.STATES.NEXT ? bundles.travel_map.frames.title_bg_blocked : bundles.travel_map.frames.title_bg);
        this.state = state;
        if (!animate) {
            this.building.setAnimation(0, state);
        }

        if (state === TravelMapItemView.STATES.PASSED) {
            this.addCheckmark();
            if (animate) {
                this.building.setAnimation(0, state);
                this.checkmark.setOpacity(0.3);
                this.checkmark.runAction(new cc.Spawn(
                    new cc.ScaleTo(0.3, 1).easing(cc.easeBackOut()),
                    new cc.FadeIn(0.15)
                ));
                AnimationsLibrary.run("disappear", this.progressBar);
            }
        } else if (state === TravelMapItemView.STATES.CURRENT) {
            this.addProgressBar();
            this.titleBg.setSpriteFrame(bundles.travel_map.frames.title_bg);
            if (animate) {
                this.progressBar.setVisible(false);
                this.progressBar.setOpacity(0.3);
                this.lock.setAnimation(0, "open");
                this.lock.setCompleteListenerOnce(function () {
                    this.building.setAnimation(0, state);
                    this.progressBar.runAction(new cc.Sequence(
                        new cc.Spawn(
                            new cc.ScaleTo(0.3, 1).easing(cc.easeBackOut()),
                            new cc.FadeIn(0.15)
                        ),
                        new cc.CallFunc(function () {
                            this.building.runAction(AnimationsLibrary.shiningLine(this.building));
                        }.bind(this))
                    ));
                }.bind(this));
            }
        } else if (state === TravelMapItemView.STATES.NEXT) {
            this.addLock();
        }
    },

    addCheckmark: function () {
        this.checkmark = new cc.Sprite(bundles.travel_map.frames.checkmark_png);
        this.checkmark.setPositionRound({ align: "center" }, { align: "center" });
        this.stateView.addChild(this.checkmark);
    },

    addLock: function () {
        var lock = this.lock = new cleverapps.Spine(bundles.travel_map.jsons.lock_json);
        lock.setPositionRound({ align: "center" }, { align: "center" });
        lock.setAnimation(0, "closed");
        this.stateView.addChild(lock);
    },

    addProgressBar: function () {
        var styles = cleverapps.styles.TravelMapItemView;

        var progressBar = this.progressBar = new PrizeBarComponent({
            progressBar: {
                width: styles.bar.width,
                progressImage: styles.bar.type.progress,
                progressBgImage: styles.bar.type.background,
                barText: {
                    text: " "
                }
            },
            reward: cleverapps.meta.getSelectedLocation().getCompleteReward(),
            autoReceiveReward: true,
            noMark: true,
            canClick: false
        });

        var progress = cleverapps.meta.getSelectedLocation().getProgress();
        progressBar.updateProgress(progress.current, progress.goal);
        progressBar.setPositionRound(styles.bar);
        this.addChild(progressBar);
    }
});

TravelMapItemView.STATES = {
    PASSED: "passed",
    CURRENT: "current",
    NEXT: "next"
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    TRAVEL_MAP_ITEM_NAME_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: {
            color: cleverapps.styles.COLORS.LIGHT_TEXT_STROKE_COLOR,
            size: 3
        },
        shadow: {
            color: cleverapps.styles.COLORS.LIGHT_TEXT_SHADOW_COLOR,
            direction: cc.size(2, -2),
            blur: 3
        }
    },

    TRAVEL_MAP_ITEM_PROGRESS_BAR_TEXT: {
        size: 22,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.TravelMapItemView = {
    width: 300,
    height: 350,
    stateView: {
        width: 40,
        height: 50
    },

    building: {
        x: { align: "center" },
        y: { align: "center" }
    },

    status: {
        icon: {
            x: { align: "center" },
            y: { align: "center" }
        }
    },

    titleBg: {
        x: { align: "center" },
        y: { align: "bottom", dy: -40 },
        size: {
            width: 370,
            height: 70
        }
    },

    titleSize: {
        width: 266,
        height: 64
    },

    titleLayout: {
        padding: 0,
        margin: 10,
        pos: {
            x: { align: "center" },
            y: { align: "center", dy: 3 }
        }
    },

    bar: {
        width: 220,
        x: { align: "center" },
        y: { align: "bottom", dy: -110 },
        type: {
            progress: bundles.travel_map.frames.bar_progress,
            background: bundles.travel_map.frames.bar_background
        },
        text: {
            font: cleverapps.styles.FONTS.TRAVEL_MAP_ITEM_PROGRESS_BAR_TEXT,
            text: "%from%%",
            dy: 2
        },
        reward: {
            x: { align: "right", dx: 40 },
            y: { align: "center", dy: 10 }
        }
    }
};