/**
 * Created by mac on 2/3/20
 */

var HomefixLocation = function (locationId) {
    BaseLocation.call(this, locationId);

    this.config = bundles["location_" + locationId].jsons.data_json.getJson();
    this.furniture = this.config.items.map(function (data) {
        return new Furniture(this, data);
    }, this);

    this.tasks = new HomeTasks(this);
};

HomefixLocation.prototype = Object.create(BaseLocation.prototype);
HomefixLocation.prototype.constructor = HomefixLocation;

HomefixLocation.prototype.load = function (data) {
    data = data || {};

    this.furnitureId = data.furnitureId === undefined ? this.firstFurnitureId() : data.furnitureId;
    this.units = data.units || {};
    this.fogs = data.fogs || [];
    this.library = data.library || {};
};

HomefixLocation.prototype.getInfo = function () {
    return {
        furnitureId: this.furnitureId !== undefined ? this.furnitureId : this.firstFurnitureId(),
        units: this.units || {},
        fogs: this.fogs || [],
        library: this.library || {}
    };
};

HomefixLocation.prototype.complete = function () {
    this.completed = true;
};

HomefixLocation.prototype.isCompleted = function () {
    return this.completed;
};

HomefixLocation.prototype.amountLevelStars = function (level) {
    return level.isHard() ? 3 : 1;
};

HomefixLocation.prototype.onUnitsLoaded = function () {
    if (!this.tasks.actives) {
        this.tasks.setActives();
    }
};

HomefixLocation.prototype.getProgress = function () {
    var goal = 0;
    var current = 0;
    this.getFurniture().forEach(function (furniture) {
        furniture.stages.forEach(function (stage) {
            goal += stage.cost;
            if (furniture.id <= this.furnitureId) {
                current += stage.cost;
            }
        }.bind(this));
    }.bind(this));
    return {
        goal: goal,
        current: current
    };
};

HomefixLocation.prototype.handleMakeChoiceWindow = function (component) {
    this.trigger("handleMakeChoice", component);
};

HomefixLocation.prototype.firstFurnitureId = function () {
    return -1;
};

HomefixLocation.prototype.getConfig = function () {
    return this.config;
};

HomefixLocation.prototype.getFurniture = function () {
    return this.furniture;
};

HomefixLocation.prototype.getCurrentFurnitureId = function () {
    return this.furnitureId;
};

HomefixLocation.prototype.setCurrentFurnitureId = function (newId) {
    this.furnitureId = newId;
    this.save();
};

HomefixLocation.prototype.getFurnitureById = function (id) {
    var furniture = this.getFurniture();

    for (var i in furniture) {
        if (furniture[i].id === id) {
            return furniture[i];
        }
    }
    return undefined;
};

HomefixLocation.prototype.getFurnitureByName = function (name) {
    var furniture = this.getFurniture();

    for (var i in furniture) {
        if (furniture[i].name === name) {
            return furniture[i];
        }
    }
    return undefined;
};

HomefixLocation.prototype.getMaxFurniture = function () {
    return this.getFurnitureById(this.getCurrentFurnitureId());
};

HomefixLocation.prototype.saveUnits = function (units) {
    this.units = units;
    this.save();
};

HomefixLocation.prototype.saveFogs = function (fogs) {
    this.fogs = fogs;
    this.save();
};

HomefixLocation.prototype.saveUnitsLibrary = function (unitsLibrary) {
    this.library = unitsLibrary;
    this.save();
};

if (cleverapps.config.debugMode) {
    HomefixLocation.prototype.getNextId = function () {
        var furniture = this.getFurniture();
        var currentFurnitureId = this.getCurrentFurnitureId();

        for (var i = 0; i < furniture.length; i++) {
            var currentFurniture = furniture[i];

            if (currentFurniture.id === currentFurnitureId) {
                var nextFurniture = furniture[i + 1];
                return nextFurniture ? nextFurniture.id : currentFurnitureId + 1;
            }
        }
    };
}

HomefixLocation.prototype.giveNewTask = function (f, furniture) {
    this.tasks.add(furniture);
    if (furniture.id > this.getCurrentFurnitureId()) {
        this.setCurrentFurnitureId(furniture.id);
    }

    this.trigger("newTask", furniture, f);
};

HomefixLocation.prototype.getFurnitureToUpgrade = function () {
    for (var id in this.tasks.actives) {
        if (this.tasks.actives[id].canBeUpgraded()) {
            return this.tasks.actives[id];
        }
    }

    return undefined;
};

HomefixLocation.prototype.getFurnitureToOpen = function () {
    if (!cleverapps.config.saveUnitsEnabled) {
        return [];
    }
    return this.getFurniture().filter(function (furniture) {
        return furniture.readyToOpen() && !this.tasks.isActive(furniture);
    }.bind(this));
};

HomefixLocation.prototype.getActiveFurniture = function () {
    return this.getFurniture().filter(function (furniture) {
        return furniture.readyToOpen() && furniture.id <= this.getCurrentFurnitureId();
    }.bind(this));
};

HomefixLocation.prototype.moveNext = function (furniture, f) {
    var cost = furniture.getInfo().cost;
    cleverapps.meta.logic.changeStars(-cost);

    if (!furniture.nextStage()) {
        this.tasks.onFurnitureCompleted(furniture);
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.METHA_ITEM + "_" + this.locationId + "_" + furniture.id);
    }
    this.trigger("change:stage", furniture, f);
};

HomefixLocation.prototype.animateNewTask = function (furniture, f) {
    this.trigger("animateNewTask", furniture, f);
};

HomefixLocation.prototype.createDialogue = function (dialogueName) {
    var dialogue = this.getConfig().dialogues[dialogueName];
    if (!dialogue) {
        return undefined;
    }

    return new Dialogue(dialogue, {
        autoClose: true,
        showUp: true
    });
};

HomefixLocation.prototype.getCompleteReward = function () {
    return {
        hard: 200
    };
};

HomefixLocation.GetFurnitureProgress = function (level, furnitureIndex, currentStage) { /// ?
    var home = cleverapps.meta.getSelectedLocation();

    var data = {
        furnitureId: home.furniture[furnitureIndex].id,
        stars: cleverapps.meta.logic.stars,
        items: {}
    };

    home.furniture.forEach(function (furniture, index) {
        var choice;
        var stage = 0;
        if (index < furnitureIndex) {
            if (furniture.hasChoices()) {
                choice = furniture.choice || 0;
            }
            stage = furniture.amountStages();
        } else if (index === furnitureIndex) {
            stage = currentStage;
        }

        data.items[furniture.id] = {
            choice: choice,
            stage: stage
        };
    });

    return data;
};
