/**
 * Created by razial on 16.01.2025.
 */

var HomefixLogic = function () {
    this.currentLocationId = 0;
    this.stars = 0;
};

HomefixLogic.prototype.reset = function () {
    this.currentLocationId = 0;
    this.stars = 0;
};

HomefixLogic.prototype.getMainLocation = function () {
    var availableLocations = cleverapps.meta.listAvailableLocations();
    return this.currentLocationId > availableLocations[availableLocations.length - 1] ? availableLocations[availableLocations.length - 1] : this.currentLocationId;
};

HomefixLogic.prototype.getLocationClass = function () {
    return HomefixLocation;
};

HomefixLogic.prototype.getMainScene = function () {
    return HomefixScene;
};

HomefixLogic.prototype.listAvailableLocations = function () {
    var ids = [];
    var id = 0;

    Object.keys(bundles).forEach(function (bundleName) {
        var bundle = bundles[bundleName];
        if (!bundle) {
            return;
        }

        if (bundle.meta.location !== undefined) {
            ids.push(id);
            id++;
        }
    });

    return ids;
};

HomefixLogic.prototype.listActiveLocations = function () {
    var locationId = this.currentLocationId;

    var available = cleverapps.meta.listAvailableLocations();
    if (available.length && available[available.length - 1] < locationId) {
        locationId = available[available.length - 1];
    }

    return [locationId - 2, locationId - 1, locationId].filter(function (id) {
        return id >= 0;
    });
};

HomefixLogic.prototype.isCompleted = function () {
    var available = cleverapps.meta.listAvailableLocations();
    return !available.length || available[available.length - 1] < this.currentLocationId;
};

HomefixLogic.prototype.moveNextLocation = function () {
    this.currentLocationId += 1;
};

HomefixLogic.prototype.processChatMessage = function (message) {
    if (message.metha.home) {
        var location = cleverapps.meta.getSelectedLocation();
        location.load(HomefixLocation.GetFurnitureProgress(message.home.metha.level, message.metha.home.furniture, message.metha.home.stage));
        location.save();
    }
};

HomefixLogic.prototype.changeStars = function (stars, silent) {
    this.stars += stars;
    cleverapps.meta.save();

    if (!silent) {
        this.onChangeStars(stars);
    }
};

HomefixLogic.prototype.onChangeStars = function (stars) {
    cleverapps.meta.trigger("changeStars", stars);
};
